import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const Button = styled.button`
  ${({ isOpen }) =>
    isOpen &&
    css`
      position: fixed;
      z-index: 1000;
      top: 24px;
    `}

  display: flex;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
`

const Box = styled.span`
  width: 40px;
  height: 18px;
  display: inline-block;
  position: relative;
  z-index: 9999;
`

const Inner = styled.span`
  display: block;
  top: auto;
  bottom: 0;
  margin-top: -1px;

  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &,
  ::before,
  ::after {
    width: 40px;
    height: 2px;
    background-color: #272727;
    position: absolute;
  }

  ::before,
  ::after {
    content: '';
    display: block;
  }

  ::before {
    top: -8px;
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  ::after {
    top: -16px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translate3d(0, -8px, 0) rotate(-45deg);
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      ::after {
        top: 0;
        opacity: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
      }

      ::before {
        top: 0;
        transform: rotate(-90deg);
        transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    `}
`

const Label = styled.span`
  font-weight: 900;
  font-size: 16px;
  margin-left: 12px;
  line-height: 18px;
`

const Hamburger = ({ isOpen, onClick, label, ...props }) => (
  <Button type='button' onClick={onClick} isOpen={isOpen} {...props}>
    <Box>
      <Inner isOpen={isOpen} />
    </Box>
    {label && <Label>{label}</Label>}
  </Button>
)

Hamburger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Hamburger
