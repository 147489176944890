import api from '../providers/api'
import { FaArrowRight } from 'react-icons/fa'
import { GiShipWheel } from 'react-icons/gi'
import { useNavigate } from '@reach/router'
import { color, layout, space, typography } from 'styled-system'
import { Flex, Text } from './ui'
import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useSessionStorageState, useWindowSize } from '../hooks'

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled(GiShipWheel)`
  animation: ${spinAnimation} 1.5s infinite linear;
`

const Input = styled.input`
  ${typography}
  ${color}
  ${space}
  ${layout}
  border: none;
  border-bottom: 2px solid;
  border-color: ${({ hasError }) => (hasError ? '#FF5616' : '#272727')};

  flex: 1;
`

const Button = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${typography}

  svg {
    margin-right: 6px;
  }

  :disabled {
    cursor: not-allowed;
  }
`

const VoucherValidation = () => {
  const [, setVoucher] = useSessionStorageState('voucher')
  const [voucherInputValue, setVoucherInputValue] = useState('')
  const [isValidatingVoucher, setIsValidatingVoucher] = useState(false)
  const [hasVoucherValidationError, setHasVoucherValidationError] = useState(false)
  const [voucherValidationErrorMessage, setVoucherValidationErrorMessage] = useState('')
  const windowSize = useWindowSize()
  const navigate = useNavigate()

  const handleVoucherValidation = async () => {
    setIsValidatingVoucher(true)

    setHasVoucherValidationError(false)
    setVoucherValidationErrorMessage('')

    try {
      const response = await api.get(`/vouchers/${voucherInputValue}`)

      setVoucher(response.data.code)
      setIsValidatingVoucher(false)

      if (typeof window !== 'undefined') {
        const currentStep = JSON.parse(localStorage.getItem('currentStep'))

        if (currentStep === '/conhecendo-o-navio/obrigado') {
          localStorage.clear()
        }
      }

      navigate('/conhecendo-o-navio')
    } catch (e) {
      setIsValidatingVoucher(false)
      setHasVoucherValidationError(true)

      if (e.response?.status === 400) {
        setVoucherValidationErrorMessage('Voucher inválido')
      } else {
        setVoucherValidationErrorMessage('Não foi possível validar o voucher, tente novamente.')
      }
    }
  }

  const Loading = () => {
    if (windowSize.width < 1200) {
      return (
        <>
          <Spinner />
        </>
      )
    }

    return (
      <>
        <Spinner size='48' />
      </>
    )
  }

  const ArrowRight = () => {
    if (windowSize.width < 1200) {
      return <FaArrowRight />
    }

    return <FaArrowRight size='48' />
  }

  return (
    <Flex flexDirection='column' mb={[10]} ml={[15, 30]}>
      <Flex
        alignItems='center'
        color='#272727'
        flexWrap='wrap'
        sx={{
          ':hover button': {
            transform: 'translate(6px, 0)',
          },
          button: { marginLeft: ['10px', '32px'], transition: 'transform 0.2s linear' },
        }}
      >
        <Input
          fontSize={[16, 48]}
          fontWeight='900'
          hasError={hasVoucherValidationError}
          lineHeight='1.4'
          maxWidth={['100%', 660]}
          minWidth={[214, 660]}
          onChange={({ target }) => setVoucherInputValue(target.value)}
          placeholder='Insira o código do seu voucher'
          value={voucherInputValue}
        />
        <Button disabled={isValidatingVoucher} fontSize={[16, 16, 24]} onClick={handleVoucherValidation} type='button'>
          {isValidatingVoucher ? <Loading /> : <ArrowRight />}
        </Button>
      </Flex>
      {hasVoucherValidationError && (
        <Text color='#FF5616' fontSize={[16, 16, 24]}>
          {voucherValidationErrorMessage}
        </Text>
      )}
    </Flex>
  )
}

export default VoucherValidation
