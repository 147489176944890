import 'slick-carousel/slick/slick.css'
import { breakpoints } from '../styles/theme'
import Slick from 'react-slick'
import styled from 'styled-components'
import useWindowSize from '../hooks/useWindowSize'
import { Box, Flex, ProgressBar, Text } from './ui'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import React, { useState } from 'react'

const Button = styled.button`
  border: 2px solid #d2d2d2;
  background: transparent;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d2d2d2;
  cursor: pointer;
  margin: 0 6px;

  @media (min-width: ${breakpoints[1]}) {
    width: 32px;
    height: 32px;
  }
`

const ThreePillarCarousel = (props) => {
  const [slider, setSlider] = useState(null)
  const [activeSlide, setActiveSlide] = useState(1)

  const windowSize = useWindowSize()

  return (
    <Flex flexDirection='column'>
      <Text as='h2' fontSize={[32, 48, 84]} fontWeight='900' lineHeight={['39px', '57px', '101px']} mb={[24, 32]}>
        Os Três Pilares
      </Text>

      <Box>
        <Slick
          ref={(slider) => setSlider(slider)}
          arrows={false}
          autoplay
          autoplaySpeed={3000}
          beforeChange={(_, next) => setActiveSlide(next + 1)}
          dots={false}
          fade
          infinite
          slidesToScroll={1}
          slidesToShow={1}
          speed={1000}
        >
          <Box>
            <Flex flexDirection='column'>
              <Text fontSize={[14, 16, 24]} lineHeight={['18px', '20px', '31px']}>
                Existe uma perenidade no teu ser:
              </Text>
              <Text
                as='q'
                fontFamily='Merriweather'
                fontSize={[14, 24, 42]}
                fontStyle='italic'
                lineHeight={['18px', '31px', '52px']}
              >
                Les jours s'en vont je demeure
              </Text>
              <Text
                as='strong'
                fontSize={[14, 16, 24]}
                fontWeight='900'
                lineHeight={['17px', '19px', '29px']}
                mt={[12, 16, 24]}
              >
                Guillaume Apollinaire, Le Pont Mirabeau
              </Text>
            </Flex>
          </Box>

          <Box>
            <Flex flexDirection='column'>
              <Text fontSize={[14, 16, 24]} lineHeight={['18px', '20px', '31px']}>
                Existe uma continuidade:
              </Text>
              <Text
                as='q'
                fontFamily='Merriweather'
                fontSize={[14, 24, 42]}
                fontStyle='italic'
                lineHeight={['18px', '31px', '52px']}
              >
                caminante, no hay camino, <br />
                se hace camino al andar.
              </Text>
              <Text
                as='strong'
                fontSize={[14, 16, 24]}
                fontWeight='900'
                lineHeight={['17px', '19px', '29px']}
                mt={[12, 16, 24]}
              >
                Antonio Machado, Caminante
              </Text>
            </Flex>
          </Box>

          <Box>
            <Flex flexDirection='column'>
              <Text fontSize={[14, 16, 24]} lineHeight={['18px', '20px', '31px']}>
                Existe uma escolha abdicante:
              </Text>
              <Text
                as='q'
                fontFamily='Merriweather'
                fontSize={[14, 24, 42]}
                fontStyle='italic'
                lineHeight={['18px', '31px', '52px']}
              >
                Two roads diverged in a yellow wood, <br /> And sorry I could not travel both
              </Text>
              <Text
                as='strong'
                fontSize={[14, 16, 24]}
                fontWeight='900'
                lineHeight={['17px', '19px', '29px']}
                mt={[12, 16, 24]}
              >
                Robert Frost, The road not taken
              </Text>
            </Flex>
          </Box>
        </Slick>

        <Flex mt={[24, 32]}>
          <ProgressBar current={activeSlide} total={3} />

          <Flex ml={[18, 26]}>
            <Button onClick={() => slider.slickPrev()}>
              {windowSize.width > +breakpoints[0].replace('px', '') ? (
                <FaArrowLeft size='16' />
              ) : (
                <FaArrowLeft size='12' />
              )}
            </Button>
            <Button onClick={() => slider.slickNext()}>
              {windowSize.width > +breakpoints[0].replace('px', '') ? (
                <FaArrowRight size='16' />
              ) : (
                <FaArrowRight size='12' />
              )}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}

export default ThreePillarCarousel
