import bgDots1 from '../images/bg-dots1.svg'
import bgDots2 from '../images/bg-dots2.svg'
import bgDots3 from '../images/bg-dots3.svg'
import bgForWho from '../images/bg-for-who.jpg'
import bgLines from '../images/bg-lines.svg'
import bgPapers from '../images/bg-papers.svg'
import bgVouchers from '../images/vouchers.svg'
import Navbar from '../components/Navbar'
import SEO from '../components/SEO'
import ThreePillarCarousel from '../components/ThreePillarCarousel'
import { useWindowSize } from '../hooks'
import VoucherValidation from '../components/VoucherValidation'
import { Absolute, Box, Container, Flex, Text, VideoWrapper } from '../components/ui'
import { FaArrowRight, FaInstagram } from 'react-icons/fa'
import React, { useEffect, useRef, useState } from 'react'

export default function IndexPage () {
  const whatIsSectionRef = useRef(null)
  const forWhoSectionRef = useRef(null)
  const forWhoSectionContentRef = useRef(null)
  const [whatIsSectionMarginLeft, setWhatIsSectionMarginLeft] = useState('auto')
  const [forWhoImageLeft, setForWhoImageLeft] = useState(0)
  const windowSize = useWindowSize()

  const whatIsTexts = [
    'Podemos dividir de forma mais etimológica: o que seria um mapa? O que seria uma biografia?',
    'Um mapa é nada menos que representações gráficas que facilitam a compreensão espacial de coisas, conceitos, condições, processos ou eventos no mundo humano.',
    'Uma biografia é uma narrativa, uma imagem, de forma que através dela possa visualizar e estabelecer uma continuidade entre todos os instantes que formam tua vida.',
    'É, em suma, um documento que mostrará como tu foste, como tu és e como tu serás.',
    () => (
      <>
        O Mapa Biográfico é um método que articula: a descrição das 12 camadas da personalidade, a jornada do herói, os
        3 atos, simbolismo astrológico, as 4 causas de Aristóteles, os 4 temperamentos, o "Big Five", as 7 Moradas
        interior, 7 faculdades humanas, Circunstância & Chamado, 4 castas hindus e os <strong>TRÊS MASTROS</strong>.
      </>
    ),
  ]

  useEffect(() => {
    if (whatIsSectionRef && whatIsSectionRef.current) {
      setWhatIsSectionMarginLeft(+getComputedStyle(whatIsSectionRef.current).marginLeft.replace('px', ''))
    }

    if (forWhoSectionRef && forWhoSectionRef.current && forWhoSectionContentRef && forWhoSectionContentRef.current) {
      const marginLeft = +getComputedStyle(forWhoSectionRef.current).marginLeft.replace('px', '')
      const width = +getComputedStyle(forWhoSectionContentRef.current).width.replace('px', '')

      setForWhoImageLeft(marginLeft + width)
    }
  }, [whatIsSectionRef, windowSize])

  return (
    <>
      <SEO title='Tua vida é uma JORNADA' />
      <Navbar />

      <Box position='relative'>
        <Container as='header' display='flex' flexDirection='column' maxWidth={[963, 963, 1404]}>
          <Text
            as='h1'
            color='#D2D2D2'
            fontSize={[48, 100, 148]}
            fontWeight='300'
            lineHeight={['57px', '120px', '178px']}
            mb={[48, 64, 84]}
            mt={[54, 124, 164]}
            textTransform='uppercase'
          >
            Mapa{' '}
            <Text as='span' fontSize='inherit' fontWeight='900' lineHeight='inherit'>
              Biográfico
            </Text>
          </Text>
          <ThreePillarCarousel />
        </Container>
        <Absolute as='img' right='2vw' src={bgDots1} top={[30, 56, 46]} width={[24, 48]} />
        <Absolute as='img' bottom={[-80, -46, -118]} left='2vw' src={bgDots2} width={[42, 122]} />
        <Absolute
          as='img'
          right={[-360, '-20vw']}
          src={bgPapers}
          top={[370, 300, 515]}
          width={[720, 720, 900]}
          zIndex='-1'
        />
      </Box>

      <Container ref={whatIsSectionRef} as='section' display='flex' flexDirection='column' id='o-que-e' mt={[170]}>
        <Text
          as='h2'
          color='#D2D2D2'
          fontSize={[64, 136, 164]}
          fontWeight='300'
          lineHeight={['77px', '163px', '197px']}
          mb={[47]}
          sx={{ position: 'relative' }}
          textTransform='uppercase'
        >
          O que{' '}
          <Text as='span' fontSize='inherit' fontWeight='900' lineHeight='inherit'>
            é
          </Text>
          <Absolute
            as='i'
            bg='#ff5616'
            bottom={[-10, -20]}
            height={[10, 20]}
            left={-whatIsSectionMarginLeft - 24}
            width={[whatIsSectionMarginLeft + 64, whatIsSectionMarginLeft + 136, whatIsSectionMarginLeft + 164]}
          />
        </Text>
        <Flex flexDirection={['column', 'row']}>
          <Box flex='1 0 100%' maxWidth={['100%', 700, 926]} mb={[24, '0px']} mr={[0, 48]}>
            <VideoWrapper>
              <iframe allowFullscreen frameBorder='0' src='https://www.youtube.com/embed/36xkAWxJKoI' />
            </VideoWrapper>
          </Box>
          <Box flex='1'>
            {whatIsTexts.map((text, index) => (
              <Text
                key={index}
                color='#303030'
                fontSize={[16, 16, 24]}
                lineHeight={['24px', '24px', '36px']}
                mb={[16, 16, 24]}
              >
                {text instanceof Function ? text() : text}
              </Text>
            ))}
          </Box>
        </Flex>
      </Container>

      <Box position='relative'>
        <Absolute as='img' left={[-110, -154, -184]} src={bgLines} top={[78, 174, 200]} width={[190, 350, 450]} />

        <Container
          ref={forWhoSectionRef}
          as='section'
          display='flex'
          flexDirection={['column', 'row']}
          flexWrap='wrap'
          id='pra-quem-e'
          mt={[84, 124]}
        >
          <Box ref={forWhoSectionContentRef} flex='1' maxWidth={728} minWidth={['100%', 614, 728]}>
            <Text
              as='h2'
              color='#D2D2D2'
              fontSize={[64, 124, 148]}
              fontWeight='300'
              lineHeight={['77px', '148px', '178px']}
              mb={[32, 52, 36]}
              textTransform='uppercase'
            >
              Para{' '}
              <Text
                as='span'
                fontSize='inherit'
                fontWeight='900'
                lineHeight='inherit'
                ml={[34, 88, 102]}
                sx={{ display: 'block' }}
              >
                Quem É?
              </Text>
            </Text>

            <Box ml={[34, 88, 102]}>
              <Text
                as='h3'
                color='#303030'
                fontSize={[18, 24, 32]}
                fontWeight='700'
                lineHeight={['27px', '36px', '48px']}
                mb={[16, 16, 24]}
              >
                Pior que estar à deriva, é estar andando em círculos achando que está navegando para algum lugar! Se tu
                corres esse risco: o mapa biográfico é para ti.
              </Text>
              {[
                '1. Tu sabes responder com clareza de onde tu veio?',
                '2. Tu sabes responder com clareza onde tu estás hoje?',
                '3. Tu sabes responder com clareza para onde tu vais?',
              ].map((text, index) => (
                <Text
                  key={index}
                  color='#D2D2D2'
                  fontFamily='Merriweather'
                  fontSize={[16, 16, 24]}
                  fontStyle='italic'
                  lineHeight={['24px', '24px', '36px']}
                  mb={[16, 16, 24]}
                >
                  {text}
                </Text>
              ))}

              <Text color='#303030' fontSize={[16, 16, 24]} lineHeight={['24px', '24px', '36px']} mb={[16, 16, 24]}>
                Se tu respondeste <strong>NÃO</strong>, para qualquer uma das perguntas anteriores, o mapa biográfico é
                para ti
              </Text>
              <Text color='#303030' fontSize={[16, 16, 24]} lineHeight={['24px', '24px', '36px']} mb={[16, 16, 24]}>
                A resposta de "Da onde eu vim?" e "Para onde eu vou?" serve para dar lastro às nossas decisões. Para dar
                valor ao nosso presente que é justamente onde nossas decisões são feitas!
              </Text>
              <Text color='#303030' fontSize={[16, 16, 24]} lineHeight={['24px', '24px', '36px']} mb={[16, 16, 24]}>
                E te afirmo com certeza, uma decisão sem lastro é uma decisão sem valor!
              </Text>
            </Box>
          </Box>

          <Box flex='1' />
        </Container>

        <Box
          backgroundImage={`url(${bgForWho})`}
          backgroundPostion='center'
          backgroundSize='cover'
          boxShadow='10px 10px 30px #00000033'
          display={['block', 'none']}
          minHeight={337}
          ml={24}
          width={['calc(100% - 24px)', 'auto']}
        />
        <Absolute
          backgroundImage={`url(${bgForWho})`}
          backgroundPostion='center'
          backgroundSize='cover'
          bottom={[40]}
          boxShadow='10px 10px 30px #00000033'
          display={['none', 'block']}
          left={[24, forWhoImageLeft + 84, forWhoImageLeft + 124]}
          minHeight={337}
          right={0}
          top={[66]}
        />
      </Box>

      <Box bg='#272727' mt={[84, 124]} position='relative' px={24} py={[84, 120]}>
        <Absolute as='img' right={['8px', '12px', '16px']} src={bgDots3} top={[26, 104, 128]} width={[42, 160]} />
        <Absolute
          as='i'
          bg='#FF5616'
          bottom={[42, 100]}
          height={[10, 20]}
          left={[0, 'auto']}
          right={['auto', 0]}
          width={[100, 124]}
        />
        <Absolute as='i' bg='#FFFFFF' bottom={[0]} height={[0, 355]} left={[0, 46, 118]} width={[0, 10]} />
        <Absolute as='i' bg='#FF5616' bottom={[0]} height={[0, 504]} left={[0, 84, 155]} width={[0, 5]} />

        <Container as='section' id='beneficios' maxWidth={['100%', 958, 1346]} p={0}>
          <Text
            as='h2'
            color='#FF5616'
            fontSize={[32, 64, 90]}
            fontWeight='300'
            lineHeight={['39px', '77px', '108px']}
            mb={[32]}
            textTransform='uppercase'
          >
            Benefícios{' '}
            <Text
              as='span'
              color='white'
              fontSize='inherit'
              fontWeight='900'
              lineHeight='inherit'
              sx={{ display: ['block', 'inline'] }}
            >
              do Mapeamento
            </Text>
          </Text>

          <Flex flexDirection={['column', 'row']}>
            <Box flex='1'>
              <Text
                as='h3'
                color='white'
                fontSize={[17, 24, 32]}
                fontWeight='700'
                lineHeight={['27px', '36px', '48px']}
                mb={[16, 16, 24]}
              >
                Nenhuma jornada foi capaz de obter êxito sem um bom mapa para orientar-se.
              </Text>
              {[
                '1. Descubra teu temperamento.',
                '2. Saiba em qual camada de amadurecimento da personalidade tu estás.',
                '3. Ganhe clareza nos teus objetivos, projetando teu chamado a partir da tua circunstância concreta.',
                '4. Coloque tuas virtudes para trabalhar a teu favor e a favor de quem precisa de ti.',
                '5. Elenque e trate tuas faltas com ações objetivas.',
                '6. Tenha em mãos um documento que indique tuas tendências e inclinações que antecedem tuas escolhas.',
                '7. Atribua um sentido para tua narrativa biográfica.',
                '8. Situe-se nas "moradas interior".',
              ].map((text, index) => (
                <Text
                  key={index}
                  color='white'
                  fontSize={[16, 16, 24]}
                  lineHeight={['24px', '24px', '36px']}
                  mb={[16, 16, 24]}
                >
                  {text}
                </Text>
              ))}
            </Box>
            <Box flex='1' ml={[0, 48]}>
              {[
                'Tens em mãos agora um método capaz de te fornecer esse mapa, que articula os melhores tópicos da filosofia, psicologia, antropologia e desenvolvimento pessoal.',
                'O processo foi pensado exatamente para que, inicialmente, te forneça uma enorme autonomia. Esse primeiro passo não exige nenhuma mediação, isto é, é tu contigo mesmo.',
                'Mas ele não encerra por aqui, os frutos já dessa primeira etapa são: a descoberta do teu temperamento, as consciência das tendências e inclinações que antecedem tuas escolhas e o conhecimento de tuas faltas e virtudes e como ratificar ou retificá-las.',
                'Após a conclusão dessa primeira etapa que chama-se "Conhecendo o Navio" estamos aptos à partir para etapas mais elaboradas.',
                'Começa logo o teu mapeamento que ao final te passo mais informações!',
              ].map((text, index) => (
                <Text
                  key={index}
                  color='white'
                  fontSize={[16, 16, 24]}
                  lineHeight={['24px', '24px', '36px']}
                  mb={[16, 16, 24]}
                >
                  {text}
                </Text>
              ))}
            </Box>
          </Flex>
        </Container>
      </Box>

      <Box
        as='section'
        backgroundImage={`url(${bgVouchers})`}
        backgroundPosition={['-76px 110px', '-60% 110px']}
        backgroundRepeat='no-repeat'
        backgroundSize={['170px', '60%']}
        px={[24]}
        py={[84]}
      >
        <Container display='flex' id='seu-voucher' justifyContent={['flex-start', 'flex-end']}>
          <Box ml={[15]}>
            <Text
              as='h2'
              color='#D2D2D2'
              fontSize={[48, 148, 184]}
              fontWeight='300'
              lineHeight={['57px', '178px', '221px']}
              mb={[10]}
              textTransform='uppercase'
            >
              Seu{' '}
              <Text fontSize='inherit' fontWeight='900' lineHeight='inherit' ml={[30]}>
                Voucher
              </Text>
            </Text>

            <VoucherValidation />

            <Flex
              alignItems='center'
              as='a'
              color='#FF5616'
              href='https://pay.hotmart.com/I22615325L?checkoutMode=10&bid=1602969751054'
              mb={[10]}
              ml={[15, 30]}
              sx={{
                ':hover svg': {
                  transform: 'translate(6px, 0)',
                },
                svg: { marginLeft: ['10px', '32px'], transition: 'transform 0.2s linear' },
                textDecoration: 'none',
              }}
              target='_blank'
            >
              <Text color='inherit' fontSize={[16, 48]} fontWeight='900' textDecoration='none'>
                Compre um voucher
              </Text>
              {windowSize.width < 1200 && <FaArrowRight />}
              {windowSize.width >= 1200 && <FaArrowRight size='48' />}
            </Flex>
            <Flex mb={[10]} ml={[15, 30]}>
              <Text fontSize={[14, 24]}>
                Baixe{' '}
                <Text
                  as='a'
                  color='#FF5616'
                  fontSize='inherit'
                  fontWeight='900'
                  href='https://mcusercontent.com/9c8f1ca541a1325dd7c8905bc/files/99f492cb-7204-4218-bb46-ee84c9c9610e/ModeloExemplo_ConhecendoONavio.pdf'
                  target='_blank'
                >
                  aqui
                </Text>{' '}
                o PDF de exemplo!
              </Text>
            </Flex>
          </Box>
        </Container>
      </Box>
      <Box bg='#272727' justifyContent='center' p={24}>
        <Container alignItems='center' display='flex' flexDirection={['column', 'row']} justifyContent='space-between'>
          <Box mb={[1, 0]}>
            <Text color='#fff' fontSize='14px' fontWeight='bold' textAlign={['center', 'left']}>
              Mapa Biográfico &copy; 2020 - Todos os direitos reservados.
            </Text>
          </Box>
          <Flex alignItems='center'>
            <Text alignItems='center' color='#fff' fontSize='14px' lineHeigh='14px'>
              Alguma dúvida? Fale comigo no
            </Text>
            <Text
              as='a'
              color='#fff'
              display='flex'
              href='https://instagram.com/odiego.moreira'
              lineHeight='16px'
              ml={2}
              target='_blank'
            >
              <FaInstagram size='24' />
            </Text>
          </Flex>
        </Container>
      </Box>
    </>
  )
}
