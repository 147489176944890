import { FaTelegramPlane } from 'react-icons/fa'
import Hamburger from './Hamburger'
import imgBgMobileMenu from '../images/backgound-mobile-menu.svg'
import styled from 'styled-components'
import { Absolute, Box, Container, Flex } from './ui'
import { color, typography } from 'styled-system'
import React, { useState } from 'react'

const Link = styled.a`
  display: block;
  text-decoration: none;
  font-weight: 900;

  ${typography}
  ${color}

  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    text-transform: initial;
  }
`

const LinkCartografia = styled(Link)`
  padding-bottom: 2px;

  @media (min-width: 1200px) {
    border-bottom: 1px solid #d2d2d2;
  }
`

const Ul = styled((props) => <Flex as='ul' {...props} />)({ listStyle: 'none' })

const Menu = ({ onClickLink }) => {
  const manuItems = [
    {
      children: 'O que é',
      href: '#o-que-e',
    },
    {
      children: 'Para quem é',
      href: '#pra-quem-e',
    },
    {
      children: 'Benefícios',
      href: '#beneficios',
    },
  ]

  return (
    <>
      <Ul flexDirection={['column', 'row']}>
        {manuItems.map((item) => (
          <Box key={item.href} as='li' mb={[32, 0]} mr={[0, 32]}>
            <Link color={['#D2D2D2', '#272727']} fontSize={[32, 16, 24]} onClick={onClickLink} {...item} />
          </Box>
        ))}
        <Box as='li' mb={[32, 0]} mr={[0, 32]}>
          <LinkCartografia
            color={['#FF5616']}
            fontSize={[32, 16, 24]}
            href='https://cartografia.mapabiografico.com.br/'
          >
            Cartografia Biográfica
          </LinkCartografia>
        </Box>
      </Ul>

      <Ul justifyContent='space-between'>
        <Box as='li' mr={[0, 32]}>
          <Link color='#272727' fontSize={[16, 16, 24]} href='#seu-voucher' onClick={onClickLink}>
            Insira seu voucher
          </Link>
        </Box>
        <li>
          <Link
            color='#FF5616'
            fontSize={[16, 16, 24]}
            href='https://pay.hotmart.com/I22615325L?checkoutMode=10&bid=1602969751054'
            target='_blank'
          >
            Compre seu voucher
          </Link>
        </li>
      </Ul>
    </>
  )
}

const Navbar = () => {
  const [isOpen, setOpen] = useState(false)

  const handleClick = () => setOpen(!isOpen)

  return (
    <Container>
      <Box as='nav' borderBottom='2px solid #E2E2E2' position='relative' pr='120px' py={[24, 24, 32]}>
        <Box display={['block', 'none']} height={18} position='relative'>
          <Hamburger isOpen={isOpen} label='Menu' onClick={handleClick} />
        </Box>

        <Absolute right='0' top={['18px', '18px', '34px']}>
          <a
            href='https://t.me/joinchat/AAAAAEyUgNbK1DLGfD1tow'
            rel='noreferrer'
            target='_blank'
            title='Canal no telegram'
          >
            <FaTelegramPlane color='#0088cc' size={32} />
          </a>
        </Absolute>

        <Absolute
          backgroundColor='#fff'
          backgroundImage={`url('${imgBgMobileMenu}')`}
          backgroundPosition='bottom right'
          backgroundRepeat=' no-repeat'
          backgroundSize=' contain'
          display='flex'
          flexDirection='column'
          height={isOpen ? '100vh' : '0'}
          justifyContent='space-between'
          left='0'
          overflow='hidden'
          padding={isOpen ? '100px 24px 50px' : '0'}
          position='fixed'
          top='0'
          transition='all 0.3s ease-in-out'
          width={isOpen ? '100vw' : '0'}
          zIndex='999'
        >
          <Menu onClickLink={() => setOpen(false)} />
        </Absolute>

        <Box display={['none', 'flex']} justifyContent='space-between'>
          <Menu onClickLink={() => setOpen(false)} />
        </Box>
      </Box>
    </Container>
  )
}

export default Navbar
